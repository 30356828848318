import { t, Trans } from "@lingui/macro"
import { graphql } from "gatsby"
import { LocalizedLink } from "gatsby-theme-i18n"
import React from "react"
import { Seo } from "../../components/seo"
import { BaseLayout } from "../../layouts/base-layout"

const BlogPage = ({ data }) => {
  return (
    <BaseLayout>
      <Seo
        title={t`Blog`}
        description={t`Explore the Testframe blog for expert insights on photo testing, online dating, social media, and more. Find the latest articles and posts on our blog.`}
      />
      <div className="mx-auto prose prose-lg md:prose-xl">
        <h1>
          <Trans>Testframe Blog</Trans>
        </h1>

        <div>
          {data.allMdx.nodes.map((node) => (
            <article key={node.id}>
              <h2>
                <LocalizedLink
                  to={`/blog${node.frontmatter.slug}`}
                  className="text-3xl text-text font-bold no-underline"
                >
                  {node.frontmatter.title}
                </LocalizedLink>
              </h2>
              <p>
                <Trans>Posted</Trans>: {node.frontmatter.date}
              </p>
            </article>
          ))}
        </div>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
  query ($locale: String!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { locale: { eq: $locale } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "LL", locale: $locale)
          slug
          title
        }
        id
      }
    }
  }
`

export default BlogPage
